// breakpoints
export const breakpoints = ["30.0625rem", "48.0625rem", "56rem"];

const theme = {
  breakpoints,
  mediaQueries: {
    sm: `@media screen and (min-width: ${breakpoints[0]})`,
    md: `@media screen and (min-width: ${breakpoints[1]})`,
    lg: `@media screen and (min-width: ${breakpoints[2]})`,
  },
  fonts: {
    body: "Satoshi",
  },
  fontWeights: {
    regular: 400,
    medium: 500,
    bold: 700,
  },
  colors: {
    primary: "#292D78",
    text: "#000",
    background: "#fff",
  },
  sizes: {
    container: "100%",
  },
  layout: {
    container: {
      width: ["92%", "92%", "73%"],
      maxWidth: "65.75rem",
    },
    hero: {
      width: ["92%", "92%", "84%"],
      maxWidth: "75rem",
    },
    navigation: {
      width: ["92%"],
      maxWidth: "82.5rem",
    },
  },
  buttons: {
    primary: {
      bg: "#FFDA17",
      color: "text",
      cursor: "pointer",
    },
    blue: {
      bg: "#292D78",
      color: "#fff",
      cursor: "pointer",
    },
  },

  styles: {
    hr: {
      borderBottom: `none`,
    },
    // Global styles
    root: {
      "*": {
        margin: "0px",
        padding: "0px",
        fontFamily: "body",
      },
      fontFamily: "body",
      fontWeight: "regular",
      color: "text",
      // p: {
      //   fontSize: [constants.paragraph1, constants.paragraph1, constants.h4],
      // },
      // li: {
      //   fontSize: [constants.paragraph1, constants.paragraph1, constants.h4],
      // },
      h1: {
        // https://css-tricks.com/linearly-scale-font-size-with-css-clamp-based-on-the-viewport/#aa-for-those-who-dont-mind-that-edge-case
        /**
         * Minimum viewport width = 769px
         * Maximum viewport width = 1440px
         * Minimum font size = 2.5rem
         * Maximum font size = 4rem
         * 1 rem = 16px
         */
        fontSize: "clamp(2.5rem, 0.7809rem + 3.5768vw, 4rem)",
        fontWeight: "medium",
        lineHeight: 1,
      },
      h2: {
        fontSize: [
          "1.5rem",
          "1.5rem",
          "clamp(2rem, 1.1405rem + 1.7884vw, 2.75rem)",
        ],
        fontWeight: "medium",
        lineHeight: 1.2,
      },
      // h3: {
      //   fontSize: constants.h2,
      // },
      // h4: {
      //   fontSize: constants.h3,
      // },
    },
  },
};

export default theme;
